import { ref } from 'vue';
import { db } from '@/firebase';

const agentFunctions = ref([]);
const agentTaskFunctions = ref([]);
const agentCallFunctions = ref([]);

const fetchAgentFunctions = async () => {
    try {
        const snapshot = await db.collection('agent-functions').get();
        agentFunctions.value = snapshot.docs.map(doc => ({
            id: doc.id,
            name: doc.data().name,
            description: doc.data().description,
            type: doc.data().type,
        }));
        agentTaskFunctions.value = agentFunctions.value.filter(func => func.type === 'agent-task');
        agentCallFunctions.value = agentFunctions.value.filter(func => func.type === 'agent-call');
        console.log('FUNCTIONS', agentCallFunctions.value);
    } catch (error) {
        console.error('Error fetching agent functions:', error);
    }
};

export { agentCallFunctions, agentTaskFunctions, agentFunctions, fetchAgentFunctions };
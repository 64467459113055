<template>
    <div v-if="audioLoading" class="d-flex flex-column align-center mt-8">
        <p class="text-caption">Loading audio... </p>
        <v-progress-circular color="primary" indeterminate size="32"></v-progress-circular>
    </div>

    <div v-if="!audioUrl && !audioLoading" class="audio-cover">
        Audio deleted or not found
    </div>
    <div v-if="audioUrl" class="d-flex flex-column mt-8">
        <div>
            <audio class="" id="my-audio" controls preload="auto" style="flex-grow: 1;">
                <source :src="audioUrl" type="audio/mpeg">
                Your browser does not support the audio tag.
            </audio>
        </div>

    </div>
</template>

<script>

import getAudio from '@/composables/getAudio'
import { ref } from 'vue'
import { getFunctions, httpsCallable } from "firebase/functions";

export default {
    props: ['audioPath', 'sessionId'],
    setup(props) {
        const isPending = ref(false)
        const isDisabledRegenerateTranscript = ref(true)
        const isDisabledDeleteAudio = ref(true)

        const isTranscriptPending = ref(false)

        const pathSplitted = props.audioPath?.split("/")
        var sessionId = ""
        if (pathSplitted != null) {
            sessionId = pathSplitted[pathSplitted.length - 1];
            ['.m4a', '.webm'].map(format => {
                sessionId = sessionId.replace(format, '')
            });
        }

        console.log('sessionId', props.sessionId)
        console.log('audioPath', props.audioPath)

        const audioUrl = ref(null)
        const audioLoading = ref(true);

        getAudio(props.audioPath).then(url => {
            audioUrl.value = url
        }).finally(() => {
            audioLoading.value = false
        })

        const newTranscript = async () => {

            isTranscriptPending.value = true

            var regex = /gs:\/\/[a-z]+-\w+\.appspot\.com\//;
            var path = props.audioPath.replace(regex, '');

            //Call firebase function to transcript the audio
            const functions = getFunctions();
            const transcribeAudio = httpsCallable(functions, 'transcribeAudio');
            transcribeAudio(path)
                .then((result) => {
                    console.log('done', result)
                });

            isTranscriptPending.value = false

        }

        return { audioUrl, audioLoading, newTranscript, isPending, isTranscriptPending, isDisabledRegenerateTranscript, isDisabledDeleteAudio }
    }
}
</script>

<style scoped>
.delete-audio {
    background-color: #f1f3f4 !important;
    width: 54px !important;
    height: 54px !important;
}

.delete-audio:hover {
    background-color: #d8dadb !important;
    width: 54px !important;
    height: 54px !important;
}

.reload-audio {
    background-color: #f1f3f4 !important;
    width: 54px !important;
    height: 54px !important;
}

.reload-audio:hover {
    background-color: #d8dadb !important;
    width: 54px !important;
    height: 54px !important;
}

.delete-audio .v-icon {
    color: black !important;
}


.audio-controls {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.audio-wrapper {
    flex-grow: 1;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
</style>
// ReTriggerTranscriptNote.vue
<template>
    <div class="retrigger-transcript">
        <v-card flat>
            <v-card-title class="text-subtitle-1 font-weight-bold">
                Re-trigger Transcript Note
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="12">
                        <v-switch v-model="checkIsMedicalConversation" label="Check Is Medical Conversation" dense
                            hide-details class="mb-2"></v-switch>
                    </v-col>
                    <v-col cols="12">
                        <v-switch v-model="useExistingTranscript" label="Use Existing Transcript" dense hide-details
                            class="mb-4"></v-switch>
                    </v-col>
                    <v-col cols="12">
                        <v-btn color="primary" :loading="loading" :disabled="!sessionId" @click="reTriggerTranscript"
                            block>
                            Re-trigger Transcript
                        </v-btn>
                    </v-col>
                </v-row>

                <!-- Status Messages -->
                <v-alert v-if="error" type="error" dense dismissible class="mt-4" @click:close="error = null">
                    {{ error }}
                </v-alert>
                <v-alert v-if="success" type="success" dense dismissible class="mt-4" @click:close="success = null">
                    Transcript re-triggered successfully!
                </v-alert>
            </v-card-text>
        </v-card>
    </div>
</template>

<script setup>
import { ref, defineProps, onMounted } from 'vue'
//import { getRemoteConfig, getValue } from 'firebase/remote-config'

//const apiKey = ref('')

onMounted(async () => {
    // const remoteConfig = getRemoteConfig()
    // await remoteConfig.fetchAndActivate()
    // apiKey.value = getValue(remoteConfig, 'transcript_api_key').asString()
})


const props = defineProps({
    sessionId: {
        type: String,
        required: true
    }
})

const checkIsMedicalConversation = ref(false)
const useExistingTranscript = ref(false)
const loading = ref(false)
const error = ref(null)
const success = ref(null)

const reTriggerTranscript = async () => {
    if (!props.sessionId) return

    loading.value = true
    error.value = null
    success.value = null

    try {

        console.log('Re-triggering transcript note...')
        console.log('API Key:', '47d2e539-05b5-4a5d-a3e3-d6c0abe851de')
        console.log('Session ID:', props.sessionId)
        console.log('Check Is Medical Conversation:', checkIsMedicalConversation.value)
        console.log('Use Existing Transcript:', useExistingTranscript.value)

        const response = await fetch(
            'https://us-central1-nbox-6f560.cloudfunctions.net/reTriggerTranscriptNote',
            {
                method: 'POST',
                headers: {
                    'Accept': '*/*',  // Add this to match Postman
                    'Content-Type': 'application/json',
                    'x-api-key': '47d2e539-05b5-4a5d-a3e3-d6c0abe851de'
                },
                body: JSON.stringify({
                    sessionId: props.sessionId,
                    checkIsMedicalConversation: checkIsMedicalConversation.value,
                    useExistingTranscript: useExistingTranscript.value
                })
            }
        )

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`)
        }

        const data = await response.json()
        console.log('Response:', data)
        success.value = true
    } catch (err) {
        console.error('Error re-triggering transcript:', err)
        error.value = 'Failed to re-trigger transcript. Please try again.'
    } finally {
        loading.value = false
    }
}
</script>
<template>
    <v-container>
        <!-- Search Section -->
        <v-row>
            <v-col cols="12" sm="8" md="6">
                <v-text-field v-model="searchQuery" label="Search by User ID or Email" outlined dense clearable
                    @keyup.enter="searchUser">
                    <template v-slot:append>
                        <v-btn color="primary" @click="searchUser" :loading="loading">
                            Search
                        </v-btn>
                    </template>
                </v-text-field>
            </v-col>
        </v-row>

        <!-- Selected User Details -->
        <v-row v-if="selectedUser">
            <v-col cols="12">
                <v-card class="user-details-card">
                    <v-card-title class="d-flex align-center py-4 px-4">
                        <v-avatar color="primary" class="mr-4" size="42">
                            <span class="text-h6 white--text">
                                {{ selectedUser.name ? selectedUser.name.charAt(0).toUpperCase() : 'U' }}
                            </span>
                        </v-avatar>
                        <div>
                            <div class="text-h6">{{ selectedUser.name || 'No Name' }}</div>
                            <div class="text-subtitle-2 grey--text">
                                {{ selectedUser.email }}
                                <v-chip color="primary" class="ml-2" size="x-small" label>
                                    {{ selectedUser.speciality }}
                                </v-chip>
                            </div>
                        </div>
                    </v-card-title>

                    <v-divider></v-divider>

                    <v-card-text class="pa-4">
                        <v-row>
                            <!-- Basic Information -->
                            <v-col cols="12" md="4">
                                <div class="text-subtitle-1 font-weight-bold mb-3">Basic Information</div>
                                <v-list dense>
                                    <v-list-item class="mb-2">
                                        <v-list-item-icon>
                                            <v-icon color="grey">mdi-identifier</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content class="align-start">
                                            <v-list-item-subtitle>User ID</v-list-item-subtitle>
                                            <v-list-item-title class="font-weight-medium">
                                                {{ selectedUser.userId }}
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>

                                    <v-list-item class="mb-2">
                                        <v-list-item-icon>
                                            <v-icon color="grey">mdi-calendar</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content class="align-start">
                                            <v-list-item-subtitle>Created At</v-list-item-subtitle>
                                            <v-list-item-title class="font-weight-medium">
                                                {{ new Date(selectedUser.createdAt.seconds * 1000).toLocaleDateString()
                                                }}
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>

                                    <v-list-item class="mb-2">
                                        <v-list-item-icon>
                                            <v-icon color="grey">mdi-translate</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content class="align-start">
                                            <v-list-item-subtitle>Language</v-list-item-subtitle>
                                            <v-list-item-title class="font-weight-medium">
                                                {{ selectedUser.language }}
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>

                                    <v-list-item class="mb-2">
                                        <v-list-item-icon>
                                            <v-icon color="grey">mdi-counter</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content class="align-start">
                                            <v-list-item-subtitle>Session Count (30 days)</v-list-item-subtitle>
                                            <v-list-item-title class="font-weight-medium">
                                                {{ selectedUser.counter30 || 0 }}
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>

                                    <v-list-item class="mb-2">
                                        <v-list-item-icon>
                                            <v-icon color="grey">mdi-hospital-building</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content class="align-start">
                                            <v-list-item-subtitle>Clinic</v-list-item-subtitle>
                                            <v-list-item-title class="font-weight-medium">
                                                <template v-if="selectedUser.clinicId">
                                                    <v-chip color="primary" small label>
                                                        {{ selectedUser.clinicId }}
                                                    </v-chip>
                                                </template>
                                                <template v-else>
                                                    <span class="text-caption grey--text">Not part of a clinic</span>
                                                </template>
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                            </v-col>

                            <!-- Codes and Templates -->
                            <v-col cols="12" md="4">
                                <div class="text-subtitle-1 font-weight-bold mb-3">Codes & Templates</div>
                                <v-list dense>
                                    <v-list-item class="mb-2">
                                        <v-list-item-icon>
                                            <v-icon color="grey">mdi-ticket-percent</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content class="align-start">
                                            <v-list-item-subtitle>Promo Code</v-list-item-subtitle>
                                            <v-list-item-title class="font-weight-medium">
                                                {{ selectedUser.promoCode || 'None' }}
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>

                                    <v-list-item class="mb-2">
                                        <v-list-item-icon>
                                            <v-icon color="grey">mdi-account-multiple</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content class="align-start">
                                            <v-list-item-subtitle>Referral Code</v-list-item-subtitle>
                                            <v-list-item-title class="font-weight-medium">
                                                {{ selectedUser.referralCode }}
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>

                                    <v-list-item v-if="selectedUser.lastTemplateId" class="mb-2">
                                        <v-list-item-icon>
                                            <v-icon color="grey">mdi-file-document</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content class="align-start">
                                            <v-list-item-subtitle>Last Template ID</v-list-item-subtitle>
                                            <v-list-item-title class="font-weight-medium">
                                                {{ selectedUser.lastTemplateId }}
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                            </v-col>

                            <!-- Settings -->
                            <v-col cols="12" md="4">
                                <div class="text-subtitle-1 font-weight-bold mb-3">Settings</div>
                                <v-list dense>
                                    <v-list-item class="mb-2">
                                        <v-list-item-content class="align-start">
                                            <v-list-item-subtitle>ICD-10 Codes</v-list-item-subtitle>
                                            <v-list-item-title>
                                                <v-chip :color="selectedUser.settings?.icd10codes ? 'success' : 'grey'"
                                                    small>
                                                    {{ selectedUser.settings?.icd10codes ? 'Enabled' : 'Disabled' }}
                                                </v-chip>
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>

                                    <v-list-item class="mb-2">
                                        <v-list-item-content class="align-start">
                                            <v-list-item-subtitle>Include Session Details</v-list-item-subtitle>
                                            <v-list-item-title>
                                                <v-chip
                                                    :color="selectedUser.settings?.includeSessionDetails ? 'success' : 'grey'"
                                                    small>
                                                    {{ selectedUser.settings?.includeSessionDetails ? 'Enabled' :
                                                        'Disabled' }}
                                                </v-chip>
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>

                                    <v-list-item class="mb-2">
                                        <v-list-item-content class="align-start">
                                            <v-list-item-subtitle>Use Patient Name</v-list-item-subtitle>
                                            <v-list-item-title>
                                                <v-chip
                                                    :color="selectedUser.settings?.usePatientName ? 'success' : 'grey'"
                                                    small>
                                                    {{ selectedUser.settings?.usePatientName ? 'Enabled' : 'Disabled' }}
                                                </v-chip>
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>

                                    <v-list-item class="mb-2">
                                        <v-list-item-content class="align-start">
                                            <v-list-item-subtitle>Use Previous Note</v-list-item-subtitle>
                                            <v-list-item-title>
                                                <v-chip
                                                    :color="selectedUser.settings?.usePreviousNote ? 'success' : 'grey'"
                                                    small>
                                                    {{ selectedUser.settings?.usePreviousNote ? 'Enabled' : 'Disabled'
                                                    }}
                                                </v-chip>
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>

                                    <v-list-item class="mb-2">
                                        <v-list-item-content class="align-start">
                                            <v-list-item-subtitle>Narration</v-list-item-subtitle>
                                            <v-list-item-title class="font-weight-medium">
                                                {{ selectedUser.settings?.narration || 'Not set' }}
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>

                                    <v-list-item class="mb-2">
                                        <v-list-item-content class="align-start">
                                            <v-list-item-subtitle>Subject</v-list-item-subtitle>
                                            <v-list-item-title class="font-weight-medium">
                                                {{ selectedUser.settings?.subject || 'Not set' }}
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <!-- Error Message -->
        <v-row v-if="error">
            <v-col cols="12">
                <v-alert type="error" dense dismissible @click:close="error = null">
                    {{ error }}
                </v-alert>
            </v-col>
        </v-row>

        <!-- No Results Message -->
        <v-row v-if="noResults">
            <v-col cols="12">
                <v-alert type="info" dense>
                    No user found with this ID or email.
                </v-alert>
            </v-col>
        </v-row>
    </v-container>
</template>

<script setup>
import { ref } from 'vue'
import { db } from '@/firebase'

const searchQuery = ref('')
const selectedUser = ref(null)
const loading = ref(false)
const error = ref(null)
const noResults = ref(false)

const isEmail = (text) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailRegex.test(text)
}

const searchUser = async () => {
    if (!searchQuery.value.trim()) return

    loading.value = true
    error.value = null
    noResults.value = false
    selectedUser.value = null

    try {
        const query = searchQuery.value.trim()
        let snapshot

        if (isEmail(query)) {
            // Search by email
            console.log('Searching by email:', query)
            const querySnapshot = await db.collection('user')
                .where('email', '==', query.toLowerCase())
                .limit(1)
                .get()

            if (!querySnapshot.empty) {
                snapshot = querySnapshot.docs[0]
            }
        } else {
            // Search by ID
            console.log('Searching by ID:', query)
            snapshot = await db.collection('user').doc(query).get()
        }

        if (!snapshot || !snapshot.exists) {
            noResults.value = true
            return
        }

        selectedUser.value = {
            id: snapshot.id,
            ...snapshot.data()
        }
    } catch (err) {
        console.error('Error searching user:', err)
        error.value = 'An error occurred while searching. Please try again.'
    } finally {
        loading.value = false
    }
}
</script>

<style scoped>
.user-details-card :deep(.v-list-item__icon) {
    margin: 12px 16px 12px 0;
}

.user-details-card :deep(.v-list-item__content) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    padding-right: 8px;
}

.user-details-card :deep(.v-list-item__title) {
    text-align: left;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.user-details-card :deep(.v-chip) {
    margin-right: auto;
}

/* Updated subtitle styling */
.user-details-card :deep(.v-list-item__subtitle) {
    font-size: 0.875rem;
    color: rgba(0, 0, 0, 0.6);
    text-align: left;
    width: 100%;
    padding: 0;
    margin: 0;
    display: block;
}

.user-details-card :deep(.v-list-item) {
    padding-left: 0;
    align-items: flex-start;
}

.user-details-card :deep(.text-caption) {
    text-align: left;
    width: 100%;
}

/* Additional alignment fixes */
.user-details-card :deep(.v-list-item__content > *) {
    text-align: left;
}
</style>
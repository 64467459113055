import { getAuth } from 'firebase/auth';

const isProduction = process.env.NODE_ENV === 'production';
const baseUrl = isProduction
    ? 'https://us-central1-nbox-6f560.cloudfunctions.net'
    : 'https://us-central1-mdhub-staging.cloudfunctions.net';


const emailToUser = (clinicName, clinicianEmail, doctorName) => {
    return `
<div style="font-family: Arial, sans-serif; max-width: 600px; margin: 0 0; padding: 20px;">
            <h2>${clinicName} invited you to mdhub</h2>
<p>Hi ${clinicianEmail},</p>
<p>${doctorName} invited you to ${clinicName} on <a href="https://www.mdhub.ai">mdhub.ai</a>
- your new clinical AI assistant, designed to save you time and elevate patient care by automating documentation pre-, during- and post-patient visits.</p>
<p>If you're new to mdhub, <a href="https://app.mdhub.ai/signup?fromWebsite=true">click on this link to get started</a>.  If you already have an account with this email, the account will be automatically updated.</p>
<p>Questions? Just reply to this email.</p>
<p>Best,<br>Jost</p>
</div>
    `
}

const checkUserStatus = async (data) => {
    if (!data.email) {
        throw new Error('No clinician email provided');
    }
    const requestUrl = `${baseUrl}/checkUserStatus`;

    try {
        const currentUser = getAuth().currentUser;
        if (!currentUser) {
            throw new Error('No authenticated user found');
        }

        const idToken = await currentUser.getIdToken();
        const response = await fetch(
            requestUrl,
            {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${idToken}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    data
                })
            }
        );

        if (!response.ok) {
            throw await response.json();
        }

        const responseData = await response.json();
        return responseData.result;

    } catch (error) {
        console.error('Error checking clinician\'s email:', error);
        throw error;
    }
}

const sendEmail = async (data) => {
    const requestUrl = `${baseUrl}/sendEmail`;
    const currentUser = getAuth().currentUser;
    if (!currentUser) {
        throw new Error('No authenticated user found');
    }

    const clinicianEmails = data.to;
    const clinicName = data.clinicName;
    const idToken = await currentUser.getIdToken();

    try {
        const response = Promise.all(clinicianEmails.map(async clinicianEmail => {
            const result = await checkUserStatus({ email: clinicianEmail });
            if (result.disabled) {
                console.log('User has not be enabled yet')
            }
            const message = emailToUser(clinicName, clinicianEmail, currentUser.displayName);
            data.message = message;
            const response = await fetch(
                requestUrl,
                {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${idToken}`,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        data: {
                            ...data,
                            to: clinicianEmail
                        }
                    })
                }
            );

            if (!response.ok) {
                throw await response.json();
            }

            const responseData = await response.json();
            return responseData.result;
        }))

        return response;

    } catch (error) {
        console.error('Error sending an email:', error);
        throw error;
    }
};

export { sendEmail };
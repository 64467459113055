<template>
    <v-data-table :headers="headers" :items="items" class="elevation-1 ml-4 pr-6" height="1000">
        <template v-slot:top>
            <v-toolbar flat class="d-flex justify-center">
                <v-select v-model="selectedName" :items="names" label="Select Name" class="mr-4"></v-select>
                <v-file-input label="Import file" color="primary" @change="handleFileUpload" class="pt-5 mx-auto"
                    prepend-icon="" variant="outlined"></v-file-input>
            </v-toolbar>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
            <v-icon size="small" class="me-2" @click="openDrawer(item)">
                mdi-email
            </v-icon>
        </template>
        <template v-slot:[`item.companyUrl`]="{ item }">
            <a href="#" @click="openInNewWindow(item.companyUrl, $event)">{{ item.companyUrl }}</a>
        </template>
        <template v-slot:[`item.companyLinkedin`]="{ item }">
            <a href="#" @click="openInNewWindow(item.companyLinkedin, $event)">{{ item.companyLinkedin }}</a>
        </template>
        <template v-slot:[`item.contactLinkedinUrl`]="{ item }">
            <a href="#" @click="openInNewWindow(item.contactLinkedinUrl, $event)">{{ item.contactLinkedinUrl }}</a>
        </template>
    </v-data-table>

    <v-navigation-drawer v-model="drawer" location="right" temporary :width="800" :scrim="true">
        <v-card>
            <v-card-title class="headline text-left">{{ selectedClinicName }}</v-card-title>
            <v-card-subtitle class="text-left">Contact: {{ selectedClinic.contactEmail }} </v-card-subtitle>
            <v-card-subtitle class="text-left">Clinic Size: {{ selectedClinic.size }} </v-card-subtitle>
            <v-card-subtitle class="text-left">Location: {{ selectedClinic.companyLocation }}</v-card-subtitle>
            <v-card-subtitle class="text-left">Email: {{ selectedClinic.email }}</v-card-subtitle>
            <v-card-subtitle class="text-left">Contact Email: {{ selectedClinic.contactEmail }}</v-card-subtitle>
            <v-card-text>
                <v-text-field label="Subject" v-model="generatedEmail.subject" outlined></v-text-field>
                <v-btn class="mb-6" color="primary" text @click="copyToClipboard(generatedEmail.subject)">Copy
                    Subject</v-btn>
                <v-textarea label="Email Body" v-model="generatedEmail.emailBody" rows="15" outlined></v-textarea>
                <v-btn class="mb-6" color="primary" text @click="copyToClipboard(generatedEmail.emailBody)">Copy Email
                    Body</v-btn>
                <v-textarea label="LinkedIn Message" v-model="generatedEmail.linkedinMessage" rows="5"
                    outlined></v-textarea>
                <v-btn color="primary" text @click="copyToClipboard(generatedEmail.linkedinMessage)">Copy LinkedIn
                    Message</v-btn>
            </v-card-text>
            <v-card-actions>
                <v-btn color="primary" text @click="drawer = false">Close</v-btn>
                <v-btn color="secondary" text @click="saveEmail">Save</v-btn>
                <v-btn color="success" text @click="sendEmail">Send</v-btn>
            </v-card-actions>
        </v-card>
    </v-navigation-drawer>
</template>

<script>
import OpenAI from "openai";
import Papa from "papaparse";

export default {
    data() {
        return {
            headers: [
                { title: 'Actions', value: 'actions', sortable: false },
                { title: 'Clinic Name', value: 'clinicName' },
                { title: 'Contact', value: 'contact' },
                { title: 'Size', value: 'size' },
                { title: 'Contact Linkedin URL', value: 'contactLinkedinUrl' },
                { title: 'Company URL', value: 'companyUrl' },
                { title: 'Company Phone', value: 'companyPhone' },
                { title: 'Company Address', value: 'companyAddress' },
                { title: 'Company Location', value: 'companyLocation' },
                { title: 'Email', value: 'email' },
                { title: 'Contact Email', value: 'contactEmail' },
                { title: 'Company Linkedin', value: 'companyLinkedin' },
                { title: 'Contact Linkedin URL', value: 'contactLinkedinUrl' },
                { title: 'Contact Phone', value: 'contactPhone' },
                { title: 'Photo copy', value: 'photoCopy' },
                { title: 'Contacted Person', value: 'contactedPerson' },
                { title: 'Contacted Company', value: 'contactedCompany' },
                { title: 'Replied Person', value: 'repliedPerson' },
                { title: 'Replied Company', value: 'repliedCompany' },
            ],
            items: [],
            drawer: false,
            selectedClinic: {},
            selectedClinicName: '',
            generatedEmail: { subject: '', emailBody: '', linkedinMessage: '' },
            emailCache: {}, // Add this line to store generated emails
            selectedName: 'Dominik',
            names: ['Dominik', 'Efren']
        };
    },
    methods: {
        handleFileUpload(event) {
            const file = event.target.files[0];
            if (file && file.type === "text/csv") {
                Papa.parse(file, {
                    header: true,
                    skipEmptyLines: true,
                    complete: async (result) => {
                        this.items = result.data.map(row => ({
                            clinicName: row["Clinic Name"],
                            photoCopy: row["Photo copy"],
                            contact: row["Contact"],
                            size: row["Size"],
                            contactedPerson: row["Contacted Person"],
                            contactedCompany: row["Contacted Company"],
                            repliedPerson: row["Replied Person"],
                            repliedCompany: row["Replied Company"],
                            companyUrl: row["Company URL"],
                            companyPhone: row["Company Phone"],
                            companyAddress: row["Company Address"],
                            companyLocation: row["Company Location"],
                            email: row["Email"],
                            companyLinkedin: row["Company Linkedin"],
                            contactLinkedinUrl: row["Contact Linkedin URL"],
                            contactEmail: row["Contact Email"],
                            contactPhone: row["Contact Phone"]
                        }));

                        // Generate emails for all items and store them in the cache
                        for (const item of this.items) {
                            const emailContent = await this.generateEmail(item);
                            this.emailCache[item.clinicName] = emailContent; // Store generated email in cache
                        }
                    },
                    error: (error) => {
                        console.error("Error parsing CSV file:", error);
                    }
                });
            } else {
                console.error("Please select a valid CSV file");
            }
        },
        async openDrawer(item) {
            this.selectedClinicName = item.clinicName;
            this.selectedClinic = item;
            this.drawer = true;

            if (this.emailCache[item.clinicName]) {
                this.generatedEmail = this.emailCache[item.clinicName];
            } else {
                this.generatedEmail = { subject: 'Generating email...', emailBody: '', linkedinMessage: '' };
            }
        },
        async generateEmail(item) {
            var instructions1 = ''
            if (this.selectedName == 'Dominik') {
                //item.contact = 'Dominik'
                instructions1 = '- My name is Dominik, and I am the co-founder and CEO of mdhub.'
            } else {
                instructions1 = '- My name is Efren, and I am the co-founder of mdhub.'
            }

            const prompt = `
                    Context:
                    We are mdhub, a startup from Silicon Valley that is building a platform to help clinics and mental health providers run their practices more efficiently. We automate the creation of clinical notes, patient follow-up, and pre-charting, allowing our users to focus on their patients and save 2 hours on administrative work. We were recently accepted into the prestigious Y Combinator (YC) program and are looking to expand our user base.

                    Instructions:
                    - Do not include more than 2 paragraphs. It should be a short email, personalized, and with the aim of helping them.
                    ${instructions1}
                    - To establish credibility you can:
                        - Mention that you are the co-founder of mdhub, a startup from Silicon Valley that is part of the prestigious Y Combinator program.
                        - Mention that you have thousands of mental health providers using the platform and saving 2 hours daily.
                    - The email should be personalized with the clinic name and some information about them if we have it. Feel free to add any other personalization you think would be helpful, like mentioning we have clinics in the same state or of similar size.
                    - The email should be clear and concise and should include a brief introduction to mdhub and the value we provide to clinics and mental health providers.
                    - At the end of the email, we need a clear ask/request, e.g., “[Calendly] to my Calendly, or feel free to send me any time next week that works for you to spend 15 minutes on a demo, and I can send a Zoom link over.”
                    - Your subject line should be short, intriguing, ideally personalized, and should sound human. It’s even better if your subject line can speak to what the recipient can potentially get out of opening your email.

                    Also, provide a short message for LinkedIn contact request within 300 characters, it should say: "Hi [Name], we are using AI to improve mental health care, I believe it could benefit [Clinic name] if you would like to know more I would be happy to provide further details!"

                    Clinic Information:
                    - Clinic name: ${item.clinicName}.
                    - Clinic size: ${item.size}.
                    - Clinic location: ${item.companyLocation}.
                    - Clinic email: ${item.email}.
                    - Clinic contact: ${item.contact}.

                    Please provide the output in a valid JSON: {subject: string, emailBody: string, linkedinMessage: string}`;

            try {
                const openai = new OpenAI({
                    dangerouslyAllowBrowser: true
                });

                const response = await openai.chat.completions.create({
                    model: "gpt-4o",
                    response_format: { type: "json_object" },
                    messages: [
                        {
                            "role": "system",
                            "content": [
                                {
                                    "type": "text",
                                    "text": "You are AI assistant that help me write outbound emails to clinics and mental health providers"
                                }
                            ]
                        },
                        {
                            "role": "user",
                            "content": [
                                {
                                    "type": "text",
                                    "text": `${prompt}`
                                }
                            ]
                        }
                    ],
                    temperature: 1,
                    max_tokens: 1256,
                    top_p: 1,
                    frequency_penalty: 0,
                    presence_penalty: 0,
                });

                const emailResponse = JSON.parse(response.choices[0].message.content);
                return {
                    subject: emailResponse.subject,
                    emailBody: emailResponse.emailBody,
                    linkedinMessage: emailResponse.linkedinMessage
                };
            } catch (error) {
                console.error("Error generating email:", error);
                return { subject: 'Failed to generate email', emailBody: '', linkedinMessage: '' };
            }
        },
        isValidEmail(email) {
            const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return re.test(email);
        },
        sendEmail() {
            const subject = encodeURIComponent(this.generatedEmail.subject);
            const body = encodeURIComponent(this.generatedEmail.emailBody);
            const recipientEmail = this.isValidEmail(this.selectedClinic.contactEmail) ? this.selectedClinic.contactEmail : this.selectedClinic.email;
            const mailtoLink = `mailto:${recipientEmail}?subject=${subject}&body=${body}`;
            window.location.href = mailtoLink;
        },
        openInNewWindow(url, event) {
            event.preventDefault();
            window.open(url, "", "width=1000, height=800");
        },
        copyToClipboard(text) {
            navigator.clipboard.writeText(text).then(() => {
                console.log('Text copied to clipboard');
            }).catch(err => {
                console.error('Failed to copy text: ', err);
            });
        }
    }
};
</script>

<template>
    <v-card class="mt-4">
        <v-card-title class="d-flex align-center">
            <span>Conversations</span>
            <v-spacer></v-spacer>
            <v-text-field v-model="search" append-inner-icon="mdi-magnify" label="Search" single-line hide-details
                density="compact" class="max-w-xs"></v-text-field>
        </v-card-title>

        <v-card-text>
            <v-table v-if="!loading || conversations.length > 0">
                <thead>
                    <tr>
                        <th>Call SID</th>
                        <th>Identifier</th>
                        <th>Phone Number</th>
                        <th>Started At</th>
                        <th>Ended At</th>
                        <th>Duration</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="conversation in filteredConversations" :key="conversation.callSid"
                        @click="viewConversation(conversation)" style="cursor: pointer" class="conversation-row">
                        <td>{{ conversation.callSid }}</td>
                        <td>{{ conversation.identifier }}</td>
                        <td>{{ conversation.phoneNumber }}</td>
                        <td>{{ formatDate(conversation.startedAt) }}</td>
                        <td>{{ formatDate(conversation.endedAt) }}</td>
                        <td>{{ calculateDuration(conversation.startedAt, conversation.endedAt) }}</td>
                        <td>
                            <v-btn size="small" variant="text" @click.stop="openSummary(conversation)">
                                Show Summary
                            </v-btn>
                            <audio controls preload="none" :id="conversation.recordingSid"
                                v-if="conversation.recordingSid">
                                <source :src="playRecording(conversation.recordingSid)" type="audio/mpeg">
                                Your browser does not support the audio element.
                            </audio>
                        </td>
                    </tr>
                </tbody>
            </v-table>

            <!-- Loading indicator -->
            <div v-if="loading" class="d-flex justify-center align-center pa-4">
                <v-progress-circular indeterminate></v-progress-circular>
            </div>

            <!-- No results message -->
            <div v-if="!loading && conversations.length === 0" class="text-center pa-4">
                No conversations found for this clinic
            </div>

            <!-- Pagination -->
            <div class="d-flex justify-center align-center pa-4">
                <v-btn :loading="loading" :disabled="!hasMore" @click="loadMore" color="primary">
                    Load More
                </v-btn>
            </div>
        </v-card-text>
    </v-card>

    <!-- Summary Dialog -->
    <v-dialog v-model="showSummaryDialog" max-width="600">
        <v-card>
            <v-card-title>Conversation Summary</v-card-title>
            <v-card-text>
                <div v-if="selectedConversation">
                    <div class="text-subtitle-2 font-weight-bold mb-2">Call Details</div>
                    <div class="mb-4">
                        <div><strong>Call SID:</strong> {{ selectedConversation.callSid }}</div>
                        <div><strong>Phone Number:</strong> {{ selectedConversation.phoneNumber }}</div>
                        <div><strong>Started:</strong> {{ formatDate(selectedConversation.startedAt) }}</div>
                        <v-btn :loading="loadingRegenerate" class="mt-2"
                            @click="regenerateSection(selectedConversation.id)">Regenerate</v-btn>
                    </div>

                    <div class="text-subtitle-2 font-weight-bold mb-2">Summary</div>
                    <div class="summary-text" v-html="formatSummary(selectedConversation.summary)"></div>
                </div>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text="Close" variant="text" @click="showSummaryDialog = false"></v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <!-- Interactions Dialog -->
    <v-dialog v-model="showInteractionsDialog" transition="dialog-bottom-transition" fullscreen>
        <v-card>
            <v-toolbar>
                <v-btn icon="mdi-close" @click="closeInteractionsDialog"></v-btn>

                <v-toolbar-title prepend-icon="mdi-chat">
                    Conversation Details
                </v-toolbar-title>

                <v-spacer></v-spacer>

                <v-toolbar-items>
                    <v-btn prepend-icon="mdi-text" text="Show Summary" variant="text"
                        @click="openSummary(selectedConversation)"></v-btn>
                </v-toolbar-items>
            </v-toolbar>

            <v-card-text>
                <div class="conversation-container">
                    <div v-if="loadingInteractions" class="d-flex justify-center align-center pa-4">
                        <v-progress-circular indeterminate></v-progress-circular>
                    </div>

                    <div v-else class="interactions-list">
                        <div v-for="(interaction, index) in interactions" :key="index" :class="[
                            'interaction-bubble',
                            interaction.speaker === 'ai-agent' ? 'agent' : 'human'
                        ]">
                            <div class="interaction-content">
                                <div class="speaker-label">{{ interaction.speaker === 'ai-agent' ? 'Agent' : 'User' }}
                                </div>
                                <div class="message">
                                    {{ interaction.speaker === 'ai-agent' ? interaction.label : interaction.text }}
                                </div>
                                <div class="timestamp" v-if="interaction.timestamp">
                                    {{ formatTime(interaction.timestamp) }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script setup>
import { ref, onMounted, watch, computed, defineProps } from 'vue';
import { db } from '@/firebase';
import axios from 'axios';

const props = defineProps({
    clinicId: {
        type: String,
        required: true
    }
});

// Pagination state
const conversations = ref([]);
const loading = ref(false);
const lastVisible = ref(null);
const hasMore = ref(true);
const pageSize = 10;

// UI state
const search = ref('');
const showSummaryDialog = ref(false);
const selectedConversation = ref(null);
const showInteractionsDialog = ref(false);
const interactions = ref([]);
const loadingInteractions = ref(false);
const loadingRegenerate = ref(false);

// Computed
const filteredConversations = computed(() => {
    if (!search.value) return conversations.value;

    const searchTerm = search.value.toLowerCase();
    return conversations.value.filter(conv =>
        conv.callSid?.toLowerCase().includes(searchTerm) ||
        conv.identifier?.toLowerCase().includes(searchTerm) ||
        conv.phoneNumber?.includes(searchTerm)
    );
});

// Methods
const fetchConversations = async () => {
    if (loading.value) return;

    loading.value = true;
    try {
        const query = db.collection('conversations')
            .where('clinicId', '==', props.clinicId)
            .orderBy('startedAt', 'desc')
            .limit(pageSize);

        const snapshot = await query.get();

        lastVisible.value = snapshot.docs[snapshot.docs.length - 1];
        hasMore.value = snapshot.docs.length === pageSize;

        conversations.value = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
            startedAt: doc.data().startedAt?.toDate(),
            endedAt: doc.data().endedAt?.toDate()
        }));
    } catch (error) {
        console.error('Error fetching conversations:', error);
    } finally {
        loading.value = false;
    }
};

const loadMore = async () => {
    if (loading.value || !hasMore.value) return;

    loading.value = true;
    try {
        const query = db.collection('conversations')
            .where('clinicId', '==', props.clinicId)
            .orderBy('startedAt', 'desc')
            .startAfter(lastVisible.value)
            .limit(pageSize);

        const snapshot = await query.get();

        lastVisible.value = snapshot.docs[snapshot.docs.length - 1];
        hasMore.value = snapshot.docs.length === pageSize;

        const newConversations = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
            startedAt: doc.data().startedAt?.toDate(),
            endedAt: doc.data().endedAt?.toDate()
        }));

        conversations.value = [...conversations.value, ...newConversations];
    } catch (error) {
        console.error('Error loading more conversations:', error);
    } finally {
        loading.value = false;
    }
};

const fetchInteractions = async (conversationId) => {
    loadingInteractions.value = true;
    try {
        const snapshot = await db
            .collection('conversations')
            .doc(conversationId)
            .collection('interactions')
            .orderBy('timestamp', 'asc')
            .get();

        interactions.value = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
            timestamp: doc.data().timestamp?.toDate()
        }));
    } catch (error) {
        console.error('Error fetching interactions:', error);
    } finally {
        loadingInteractions.value = false;
    }
};

const viewConversation = async (conversation) => {
    selectedConversation.value = conversation;
    showInteractionsDialog.value = true;
    await fetchInteractions(conversation.callSid);
};

const closeInteractionsDialog = () => {
    showInteractionsDialog.value = false;
    selectedConversation.value = null;
    interactions.value = [];
};

const formatDate = (date) => {
    if (!date) return 'N/A';
    return new Date(date).toLocaleString();
};

const formatTime = (timestamp) => {
    if (!timestamp) return '';
    return new Date(timestamp).toLocaleTimeString();
};

const formatSummary = (html) => {
    if (!html) return 'No summary available';
    return html
        .replace(/\n/g, '<br>')
        .replace(/\s{2,}/g, ' ');
};

const calculateDuration = (startDate, endDate) => {
    if (!startDate || !endDate) return 'N/A';

    const diffInMinutes = Math.floor((endDate - startDate) / (1000 * 60));
    const hours = Math.floor(diffInMinutes / 60);
    const minutes = diffInMinutes % 60;

    if (hours > 0) {
        return `${hours}h ${minutes}m`;
    }
    return `${minutes}m`;
};

const openSummary = (conversation) => {
    selectedConversation.value = conversation;
    showSummaryDialog.value = true;
};

const playRecording = (recordingSid) => {
    const isProduction = process.env.NODE_ENV === 'production';
    const baseUrl = isProduction
        ? 'https://us-central1-nbox-6f560.cloudfunctions.net'
        : 'https://us-central1-mdhub-staging.cloudfunctions.net';
    return `${baseUrl}/playAudio?recordingSid=${recordingSid}`;
};

const regenerateSection = async (id) => {
    if (loadingRegenerate.value) return;
    loadingRegenerate.value = true;

    const isProduction = process.env.NODE_ENV === 'production';
    const baseUrl = isProduction
        ? 'https://us-central1-nbox-6f560.cloudfunctions.net'
        : 'https://us-central1-mdhub-staging.cloudfunctions.net';

    try {
        await axios.post(
            `${baseUrl}/regenerateConversationSummaryScrapper`,
            { id },
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                withCredentials: true
            }
        );

        await fetchConversations();
        selectedConversation.value = conversations.value.find(conv => conv.id === id);
    } catch (error) {
        console.error('Error regenerating section:', error);
    } finally {
        loadingRegenerate.value = false;
    }
};

// Watchers
watch(() => props.clinicId, () => {
    if (props.clinicId) {
        lastVisible.value = null;
        hasMore.value = true;
        conversations.value = [];
        fetchConversations();
    }
});

// Lifecycle
onMounted(() => {
    if (props.clinicId) {
        fetchConversations();
    }
});
</script>

<style scoped>
.conversation-row:hover {
    background-color: rgba(0, 0, 0, 0.04);
}

.max-w-xs {
    max-width: 300px;
}

.summary-text :deep(p) {
    margin-bottom: 1em;
}

.summary-text :deep(ul),
.summary-text :deep(ol) {
    margin-left: 1.5em;
    margin-bottom: 1em;
}

.summary-text {
    line-height: 1.5;
    max-height: 400px;
    overflow-y: auto;
    padding: 1rem;
    background-color: #212121;
    border-radius: 4px;
}

.summary-text :deep(strong) {
    font-weight: 600;
}

.summary-text :deep(em) {
    font-style: italic;
}

.conversation-container {
    height: calc(100vh - 64px);
    overflow-y: auto;
    padding: 20px;
    background-color: #212121;
}

.interactions-list {
    max-width: 800px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.interaction-bubble {
    max-width: 70%;
    margin: 8px 0;
}

.interaction-bubble.agent {
    align-self: flex-start;
}

.interaction-bubble.human {
    align-self: flex-end;
}

.interaction-content {
    padding: 12px 16px;
    border-radius: 12px;
    position: relative;
}

.interaction-bubble.agent .interaction-content {
    background-color: #424242;
    border-bottom-left-radius: 4px;
}

.speaker-label {
    font-size: 0.8rem;
    color: #666;
    margin-bottom: 4px;
}

.message {
    white-space: pre-wrap;
    word-break: break-word;
}

.timestamp {
    font-size: 0.7rem;
    color: #999;
    margin-top: 4px;
    text-align: right;
}
</style>
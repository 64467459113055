// SessionSearch.vue
<template>
  <div class="session-search">
    <v-row>
      <v-col cols="12" sm="8" md="6">
        <v-text-field
          v-model="sessionQuery"
          label="Search Session by ID"
          outlined
          dense
          clearable
          @keyup.enter="searchSession"
        >
          <template v-slot:append>
            <v-btn color="primary" @click="searchSession" :loading="loading">
              Search
            </v-btn>
          </template>
        </v-text-field>
      </v-col>
    </v-row>

    <!-- Session Details -->
    <v-row v-if="selectedSession">
      <v-col cols="12">
        <v-card class="session-details-card">
          <v-card-title class="d-flex justify-space-between align-center">
            <div>Session Details</div>
            <v-chip :color="getStatusColor(selectedSession.notesStatus)" small>
              {{ selectedSession.notesStatus }}
            </v-chip>
          </v-card-title>

          <v-card-text>
            <v-row>
              <!-- Basic Info -->
              <v-col cols="12" md="4">
                <div class="text-subtitle-1 font-weight-bold mb-3">
                  Session Information
                </div>
                <v-list dense class="left-aligned-list">
                  <v-list-item>
                    <v-list-item-content class="align-start">
                      <v-list-item-subtitle class="text-left">
                        Type
                      </v-list-item-subtitle>
                      <v-list-item-title class="text-left">
                        {{ selectedSession.type }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-content class="align-start">
                      <v-list-item-subtitle class="text-left">
                        Start Time
                      </v-list-item-subtitle>
                      <v-list-item-title class="text-left">
                        {{ formatDate(selectedSession.startSession) }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-content class="align-start">
                      <v-list-item-subtitle class="text-left">
                        End Time
                      </v-list-item-subtitle>
                      <v-list-item-title class="text-left">
                        {{ formatDate(selectedSession.endSession) }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-content class="align-start">
                      <v-list-item-subtitle class="text-left">
                        State
                      </v-list-item-subtitle>
                      <v-list-item-title class="text-left">
                        <v-chip
                          :color="getStateColor(selectedSession.state)"
                          x-small
                        >
                          {{ selectedSession.state }}
                        </v-chip>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-content class="align-start">
                      <v-list-item-subtitle class="text-left">
                        Progress
                      </v-list-item-subtitle>
                      <v-list-item-title class="text-left">
                        {{ selectedSession.progress }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-content class="align-start">
                      <v-list-item-subtitle class="text-left">
                        Modality
                      </v-list-item-subtitle>
                      <v-list-item-title class="text-left">
                        {{ selectedSession.modality }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>

              <!-- IDs and References -->
              <v-col cols="12" md="4">
                <div class="text-subtitle-1 font-weight-bold mb-3">
                  References
                </div>
                <v-list dense class="left-aligned-list">
                  <v-list-item>
                    <v-list-item-content class="align-start">
                      <v-list-item-subtitle class="text-left">
                        User ID
                      </v-list-item-subtitle>
                      <v-list-item-title class="text-left">
                        {{ selectedSession.userId }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-content class="align-start">
                      <v-list-item-subtitle class="text-left">
                        Patient ID
                      </v-list-item-subtitle>
                      <v-list-item-title class="text-left">
                        {{ selectedSession.patientId }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-content class="align-start">
                      <v-list-item-subtitle class="text-left">
                        Template ID
                      </v-list-item-subtitle>
                      <v-list-item-title class="text-left">
                        {{ selectedSession.templateId }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>

              <!-- Notes and Follow-up -->
              <v-col cols="12" md="4">
                <div class="text-subtitle-1 font-weight-bold mb-3">
                  Notes & Follow-up
                </div>
                <v-list dense class="left-aligned-list">
                  <v-list-item>
                    <v-list-item-content class="align-start">
                      <v-list-item-subtitle class="text-left">
                        Additional Notes
                      </v-list-item-subtitle>
                      <v-list-item-title class="text-left">
                        <span v-if="!isEditingNotes">
                          {{
                            selectedSession.additionalNotes ||
                            'No additional notes'
                          }}
                        </span>
                        <v-textarea
                          v-else
                          v-model="newAdditionalNotes"
                          outlined
                          dense
                          auto-grow
                          rows="2"
                        />
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-btn
                      v-if="!isEditingNotes"
                      color="primary"
                      @click="editNotes"
                    >
                      Edit
                    </v-btn>
                    <v-btn
                      v-if="isEditingNotes"
                      color="primary"
                      @click="saveNotes"
                    >
                      Save
                    </v-btn>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-content class="align-start">
                      <v-list-item-subtitle class="text-left">
                        Follow-up
                      </v-list-item-subtitle>
                      <v-list-item-title class="d-flex text-left">
                        {{ truncateText(selectedSession.followup, 50) }}
                        <v-btn
                          v-if="selectedSession.followup?.length > 50"
                          text
                          x-small
                          color="primary"
                          class="ml-2"
                          @click="
                            showFullText('Follow-up', selectedSession.followup)
                          "
                        >
                          Read More
                        </v-btn>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>

              <!-- Transcript, Summary and Audio Section -->
              <!-- Replace the existing Transcript, Summary and Audio Section with this -->
              <v-col cols="12">
                <v-row>
                  <!-- Left Column: Expansion Panels -->
                  <v-col cols="12" md="8">
                    <div class="text-subtitle-1 font-weight-bold mb-3">
                      Conversation Details
                    </div>
                    <v-expansion-panels>
                      <v-expansion-panel>
                        <v-expansion-panel-content>
                          <v-list-item-subtitle class="text-left mb-2">
                            Transcript
                          </v-list-item-subtitle>
                          <div class="text-body-1">
                            {{ truncateText(selectedSession.transcript, 200) }}
                          </div>
                          <v-btn
                            v-if="selectedSession.transcript?.length > 200"
                            text
                            color="primary"
                            class="mt-2"
                            @click="
                              showFullText(
                                'Transcript',
                                selectedSession.transcript
                              )
                            "
                          >
                            Show Full Transcript
                          </v-btn>
                        </v-expansion-panel-content>
                      </v-expansion-panel>

                      <v-expansion-panel>
                        <v-expansion-panel-content>
                          <v-list-item-subtitle class="text-left mb-2">
                            Summary
                          </v-list-item-subtitle>
                          <div
                            class="text-body-1"
                            v-html="truncateText(selectedSession.summary, 200)"
                          ></div>
                          <v-btn
                            v-if="selectedSession.summary?.length > 200"
                            text
                            color="primary"
                            class="mt-2"
                            @click="
                              showFullText('Summary', selectedSession.summary)
                            "
                          >
                            Show Full Summary
                          </v-btn>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-col>

                  <!-- Right Column: Audio -->
                  <v-col cols="12" md="4">
                    <div class="text-subtitle-1 font-weight-bold mb-3">
                      Session Recording
                    </div>
                    <v-card outlined>
                      <v-card-text>
                        <v-list-item-subtitle class="text-left mb-2">
                          Audio file location
                        </v-list-item-subtitle>
                        <v-list-item-title class="text-left mb-4">
                          {{
                            selectedSession.audioPath ||
                            'No audio path available'
                          }}
                        </v-list-item-title>
                        <AudioComponent
                          :audioPath="selectedSession.audioPath"
                          :sessionId="sessionQuery"
                        />
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>

                <!-- Re-Trigger Component -->
                <v-col cols="12" class="mt-4">
                  <ReTriggerTranscript
                    :sessionId="sessionQuery"
                    @transcript-updated="searchSession"
                  />
                </v-col>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- Full Text Dialog -->
    <v-dialog v-model="dialogVisible" max-width="800">
      <v-card>
        <v-card-title>{{ dialogTitle }}</v-card-title>
        <v-card-text>
          <div v-if="dialogTitle === 'Summary'" v-html="dialogText"></div>
          <div v-else>{{ dialogText }}</div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialogVisible = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Error Message -->
    <v-row v-if="error">
      <v-col cols="12">
        <v-alert type="error" dense dismissible @click:close="error = null">
          {{ error }}
        </v-alert>
      </v-col>
    </v-row>

    <!-- No Results Message -->
    <v-row v-if="noResults">
      <v-col cols="12">
        <v-alert type="info" dense>No session found with this ID.</v-alert>
      </v-col>
    </v-row>
  </div>
</template>

<script setup>
  import { ref } from 'vue';
  import { db } from '@/firebase';
  import AudioComponent from '@/components/AudioComponent.vue';
  import ReTriggerTranscript from '@/components/ReTriggerTranscriptNote.vue';

  const sessionQuery = ref('');
  const selectedSession = ref(null);
  const loading = ref(false);
  const error = ref(null);
  const noResults = ref(false);
  const dialogVisible = ref(false);
  const dialogTitle = ref('');
  const dialogText = ref('');
  const isEditingNotes = ref(false);
  const newAdditionalNotes = ref('');

  const editNotes = () => {
    isEditingNotes.value = true;
    newAdditionalNotes.value = selectedSession.value.additionalNotes || '';
  };

  const saveNotes = async () => {
    try {
      await db.collection('sessions').doc(selectedSession.value.id).update({
        additionalNotes: newAdditionalNotes.value,
      });
      selectedSession.value.additionalNotes = newAdditionalNotes.value;
      isEditingNotes.value = false;
    } catch (err) {
      console.error('Error saving notes:', err);
    }
  };

  const searchSession = async () => {
    if (!sessionQuery.value.trim()) return;

    loading.value = true;
    error.value = null;
    noResults.value = false;
    selectedSession.value = null;

    try {
      const snapshot = await db
        .collection('sessions')
        .doc(sessionQuery.value.trim())
        .get();

      if (!snapshot.exists) {
        noResults.value = true;
        return;
      }

      selectedSession.value = {
        id: snapshot.id,
        ...snapshot.data(),
      };
    } catch (err) {
      console.error('Error searching session:', err);
      error.value = 'An error occurred while searching. Please try again.';
    } finally {
      loading.value = false;
    }
  };

  const formatDate = (timestamp) => {
    if (!timestamp) return 'N/A';
    return new Date(timestamp.seconds * 1000).toLocaleString();
  };

  const truncateText = (text, length) => {
    if (!text) return '';
    return text.length > length ? text.substring(0, length) + '...' : text;
  };

  const showFullText = (title, text) => {
    dialogTitle.value = title;
    dialogText.value = text;
    dialogVisible.value = true;
  };

  const getStatusColor = (status) => {
    const colors = {
      'Ready for Review': 'warning',
      Completed: 'success',
      'In Progress': 'info',
    };
    return colors[status] || 'grey';
  };

  const getStateColor = (state) => {
    const colors = {
      completed: 'success',
      in_progress: 'info',
      cancelled: 'error',
    };
    return colors[state] || 'grey';
  };
</script>

<style scoped>
  .session-details-card {
    border-radius: 8px;
  }

  .session-details-card :deep(.v-list-item) {
    min-height: 40px;
    padding-left: 0;
  }

  .session-details-card :deep(.v-list-item__content) {
    padding: 0;
  }

  .session-details-card :deep(.v-list-item__subtitle) {
    color: rgba(0, 0, 0, 0.6);
    font-size: 0.875rem;
    margin-bottom: 4px;
  }

  .session-details-card :deep(.v-list-item__title) {
    font-size: 1rem;
    line-height: 1.4;
  }

  .session-details-card :deep(.v-expansion-panel-content__wrap) {
    padding: 12px;
  }

  .left-aligned-list {
    padding-left: 0 !important;
  }

  /* Add more specific alignment for the list content */
  .left-aligned-list :deep(.v-list-item__content) {
    align-items: flex-start !important;
  }
</style>

<template>
    <v-container>
        <v-row>
            <v-col md="6" cols="12">
                <v-textarea auto-grow rows="2" label="System prompt" v-model="systemPrompt"
                    variant="outlined"></v-textarea>
                <v-form @submit.prevent="click">
                    <OnClickOutside @click="showArea" v-if="!showTextarea">
                        <div class="play-area text-left" ref="playArea" contenteditable="true" @input="onInput"
                            @keydown="handleKeydown">
                            <span v-html="play"></span>
                        </div>
                    </OnClickOutside>
                    <OnClickOutside v-else @trigger="showTextarea = false">
                        <v-textarea ref="area" auto-grow rows="15" variant="outlined" class="mb-0"
                            v-model="fullPrompt"></v-textarea>
                    </OnClickOutside>
                    <v-btn class="my-3" type="submit" :loading="loading" :disabled="showTextarea">submit</v-btn>
                </v-form>

                <v-textarea v-if="gptResponded" v-model="gptResponded" auto-grow variant="outlined"></v-textarea>
            </v-col>
            <v-divider vertical></v-divider>
            <v-col md="6" cols="12" class="">
                <v-select label="Model" variant="outlined" :items="models" v-model="model" item-title="name"
                    item-value="name" return-object></v-select>

                <v-btn class="my-4" @click="addSectionDialog = true">add section</v-btn>

                <v-btn class="my-4" @click="diffDialog = true">diff</v-btn>

                <div class="d-flex flex-column bigCol">
                    <div class="mb-3" v-for="(item, key) in configs" :key="key">
                        <h3 class="text-left mb-3">{{ key }}</h3>
                        <v-textarea v-model="configs[key]" label="Prompt" auto-grow variant="outlined"></v-textarea>
                        <v-divider></v-divider>
                    </div>
                </div>

            </v-col>
        </v-row>

        <v-dialog v-model="addSectionDialog" width="400">
            <v-card>
                <div class="d-flex flex-column pa-10">
                    <h3 class="mb-3">new section</h3>
                    <v-text-field label="Title" variant="outlined" v-model="newSectionTitle"></v-text-field>
                    <v-textarea label="Prompt" variant="outlined" v-model="newSectionPrompt"></v-textarea>
                    <v-btn @click="addSection">submit</v-btn>
                </div>
            </v-card>
        </v-dialog>

        <v-dialog v-model="diffDialog">
            <v-card>
                <div class="d-flex flex-column pa-10">
                    <h3 class="mb-3">Difference</h3>
                    <v-select v-model="diffOne" :items="configsArray" item-title="title"
                        item-value="content"></v-select>
                    <v-select v-model="diffTwo" :items="configsArray" item-title="title"
                        item-value="content"></v-select>
                    <span v-for="(part, index) in diff" :key="index"
                        :class="part.added ? 'added' : part.removed ? 'removed' : 'unchanged'">{{ part.value }}</span>

                </div>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script setup>
import { ref, onMounted, computed, nextTick, watch } from 'vue'
import { db } from '@/firebase.js';
import { OnClickOutside } from '@vueuse/components'
import { diffWords } from 'diff';

import axios from 'axios';

const area = ref(null)
const showTextarea = ref(false)

const fullPrompt = ref('')

const showArea = () => {
    showTextarea.value = true
    nextTick(() => {
        area.value.focus()
    })
}

const models = [
    {
        name: 'gpt4',
        endpoint: 'https://mdhub-east2.openai.azure.com/openai/deployments/mdhub-gpt4-32k/chat/completions',
        version: '2024-02-15-preview',
        // key: process.env.VUE_APP_GPT_4_API_KEY
    },
    {
        name: 'gpt4-mini',
        endpoint: 'https://gpt-4-32-east-us.openai.azure.com/openai/deployments/gpt-4-0-mini-eastUs-450/chat/completions',
        version: '2024-02-15-preview',
        // key: process.env.VUE_APP_GPT_4_MINI_API_KEY
    },
    {
        name: 'gpt4-turbo-wsus',
        endpoint: 'https://mdhub-westus.openai.azure.com/openai/deployments/gpt4-turbo/chat/completions',
        version: '2024-02-15-preview',
        // key: process.env.VUE_APP_GPT_4_TURBO_W_US_API_KEY
    },
    {
        name: 'gpt4o-west-us-3',
        endpoint: 'https://gpt4-turbo-200q.openai.azure.com/openai/deployments/gpt4o-westus3/chat/completions',
        version: '2024-02-15-preview',
        // key: process.env.VUE_APP_GPT_4_TURBO_W_US_3_API_KEY
    }
]
const model = ref(models[3])

const systemPrompt = ref('You are an AI assistant that helps summarize doctor-patient conversations.')


const configs = ref(null)



onMounted(async () => {
    let result = await db.collection('prompt-config').doc('psychiatry').get()
    configs.value = result.data()
    delete configs.value['createdAt']

})

const configsArray = computed(() => {
    return Object.entries(configs?.value)?.map(([title, content]) => ({
        title,
        content
    }));
})

const tags = computed(() => {
    let re = {}
    for (const key in configs.value) {
        if (configs.value[key]) {
            let formattedKey = `@${key.replace(/\s+/g, '-').toLowerCase()}`
            formattedKey = formattedKey.replace(/-+/g, '-');
            re[formattedKey] = configs.value[key]
        }
    }
    return re
})

const playArea = ref(null)
const gptResponded = ref('')
watch(showTextarea, (val) => {
    nextTick(() => {
        if (!val) {
            const splitText = fullPrompt.value.split('')

            const fullTextAgain = []
            let word = '';
            let insideAtWord = false;

            splitText.forEach((char,) => {
                if (insideAtWord) {
                    if (char === ' ' || char === '\n' || char === '.') {
                        console.log("WORD", word)
                        if (Object.keys(tags.value).includes(word)) {
                            fullTextAgain.push(`<span class="highlight">${word}</span>`);
                        } else {
                            fullTextAgain.push(word);
                        }
                        fullTextAgain.push(char);
                        insideAtWord = false;
                        word = '';
                    } else {
                        word += char;
                    }
                } else if (char === '@') {
                    insideAtWord = true;
                    word = '@';
                } else {
                    fullTextAgain.push(char);
                }
            })

            if (insideAtWord && Object.keys(tags.value).includes(word)) {
                fullTextAgain.push(`<span class="highlight">${word}</span>`);
            } else {
                fullTextAgain.push(word);
            }

            const resultString = fullTextAgain.join('');

            console.log('Updated content of playArea:', resultString);

            playArea.value.innerHTML = resultString
        }
    })

})

const loading = ref(false)

const click = async () => {
    loading.value = true
    let content = playArea.value.innerHTML;

    content = content.replace(/<span[^>]*>(@[^<]*)<\/span>/g, (match, tag) => {
        return tags.value[tag] || tag;
    });

    console.log('Updated content of playArea:', content);

    const messages = [
        {
            role: 'user',
            content: content,
        },
        {
            role: 'system',
            content: systemPrompt.value,
        }
    ]

    callGpt(model.value, messages)
        .then((response) => {
            gptResponded.value = response.content

            return response;
        })
        .catch((error) => {
            gptResponded.value = error.message
            console.log(error);
        })
        .finally(() => {
            loading.value = false;
        });

}

function prepareGptParameters(messages, tools) {
    let params = {
        messages,
        temperature: 0.0,
        top_p: 0.95,
        frequency_penalty: 0,
        presence_penalty: 0,
        max_tokens: 3000,
        stop: null,
    };

    if (tools) {
        params.tools = tools;
    }

    return params;
}

const callGpt = async (model, messages, tools) => {
    let apiKey = model.key
    let endpoint = model.endpoint
    let apiVersion = model.version

    const headers = {
        "api-key": apiKey,
        "Content-Type": "application/json",
    };

    const gptParameters = prepareGptParameters(messages, tools);

    try {
        let res = await axios.post(endpoint, gptParameters, {
            headers: headers,
            params: {
                'api-version': apiVersion
            }
        });

        const gptResponseMessage = res.data.choices[0].message;
        const gptResponseContent = gptResponseMessage.content;
        const gptResponseFunctions = gptResponseMessage.tool_calls;

        const gptFunctions = [];

        if (
            gptResponseFunctions !== undefined &&
            gptResponseFunctions.length !== 0
        ) {
            gptResponseFunctions.forEach((tool) => {
                const toolFunction = tool.function;
                if (toolFunction !== undefined) {
                    gptFunctions.push({
                        id: tool.id,
                        name: toolFunction.name,
                        parameters: toolFunction.arguments,
                    });
                }
            });
        }

        if (gptFunctions.length === 0) {
            const response = { content: gptResponseContent };
            return response;
        } else {
            const response = { content: "", functions: gptFunctions };
            return response;
        }
    } catch (error) {
        console.log(error)
    }
}

const addSectionDialog = ref(false)

const newSectionTitle = ref('')
const newSectionPrompt = ref('')

const addSection = async () => {
    if (!newSectionTitle.value || !newSectionPrompt.value) {
        return
    }
    configs.value[newSectionTitle.value] = newSectionPrompt.value
    newSectionTitle.value = ''
    newSectionPrompt.value = ''
    addSectionDialog.value = false
}


const diffDialog = ref(false)
const diffOne = ref('')
const diffTwo = ref('')
const diff = computed(() => {
    return diffWords(diffOne.value, diffTwo.value);
})
</script>

<style scoped>
.play-area {
    border: 1px solid #e0e0e0;
    border-radius: 3px;
    padding: 16px;
    min-height: 400px;
    overflow-y: auto;
    white-space: pre-wrap;
    outline: none;
}

::v-deep .highlight {
    color: lightblue !important;
    text-decoration: underline;
}

.bigCol {
    max-height: 80vh;
    overflow-y: auto;
}

.added {
    background-color: #e6ffed;
    color: #24292e;
}

.removed {
    background-color: #ffeef0;
    color: #24292e;
}

.unchanged {
    color: #24292e;
}
</style>
<template>
    <button @click="signInWithGoogleMethod" class="google-sign-in-button mt-6">
        Let's go
    </button>
</template>

<script>

import { useRouter } from 'vue-router'
import { GoogleAuthProvider, getAuth, signInWithPopup, } from "firebase/auth";
import getRedirectRoute from '@/functions/getRedirectRoute';

export default {
    name: 'GoogleSignInButton',
    methods: {
        async signInWithGoogleMethod() {
            let auth = null;
            try {

                const provider = new GoogleAuthProvider();
                const auth = getAuth();
                const result = await signInWithPopup(auth, provider)
                if (!result) {
                    throw new Error('Could not complete signup')
                }
                const user = result.user;
                const route = await getRedirectRoute(user);
                await this.router.push(route);
            } catch (error) {
                if (auth) {
                    auth.signOut()
                }
            }
        }
    },
    setup() {
        const router = useRouter();
        return { router }
    }


};
</script>

<style>
/* Style your button here */
.google-sign-in-button {
    /* button styles */
}
</style>
const getRedirectRoute = async (user) => {
    const isProduction = process.env.NODE_ENV === 'production';
    const baseUrl = isProduction
        ? 'https://us-central1-nbox-6f560.cloudfunctions.net'
        : 'https://us-central1-mdhub-staging.cloudfunctions.net';

    const idToken = await user.getIdToken();
    try {
        const response = await fetch(
            `${baseUrl}/getRedirectRoute`,
            {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${idToken}`,
                    'Content-Type': 'application/json'
                }
            }
        );
        if (!response.ok) {
            throw await response.json();
        }

        return await response.json();

    } catch (error) {
        console.error('Error getting redirect route:', error);
        throw error;
    }
}


export default getRedirectRoute;
<template>
    <div class="add-clinic mr-3">
        <v-dialog v-model="openFunctionModal" max-width="800" persistent>
            <template v-slot:activator="{ props: activatorProps }">
                <v-btn class="text-none font-weight-regular mr-2" prepend-icon="mdi-function-variant"
                    text="Create Agent Function" variant="tonal" v-bind="activatorProps"></v-btn>
            </template>
            <v-card prepend-icon="mdi-function-variant" title="Add Agent Functions">
                <v-card-text>
                    <v-row v-for="(func, index) in newFunctions" :key="index" dense class="mb-2">
                        <v-col cols="12" md="4">
                            <v-text-field v-model="func.name" label="Function Name" required
                                :rules="[v => !!v || 'Name is required']"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="5">
                            <v-text-field v-model="func.description" label="Function Description" required
                                :rules="[v => !!v || 'Description is required']"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="2">
                            <v-select v-model="func.type" label="Function Type" :items="types" required
                                :rules="[v => !!v || 'Type is required']"></v-select>
                        </v-col>
                        <v-col cols="12" md="1" class="d-flex align-center">
                            <v-btn v-if="index === newFunctions.length - 1" icon="mdi-plus" size="small" variant="text"
                                @click="addFunctionRow"></v-btn>
                            <v-btn v-if="newFunctions.length > 1" icon="mdi-delete" size="small" variant="text"
                                color="error" @click="removeFunctionRow(index)"></v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text="Cancel" variant="plain" @click="closeFunctionModal"></v-btn>
                    <v-btn color="primary" text="Save" variant="tonal" :disabled="!isValidFunctions"
                        @click="saveFunctions"></v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import { db } from '@/firebase';
import { fetchAgentFunctions } from '@/composables/useAgentFunctions';

const openFunctionModal = ref(false);
const newFunctions = ref([{ name: '', description: '', type: '' }]);
const types = ['agent-call', 'agent-task'];

const isValidFunctions = computed(() => {
    return newFunctions.value.every(func =>
        func.name.trim() !== '' && func.description.trim() !== '' && func.type.trim() !== ''
    );
});

const addFunctionRow = () => {
    newFunctions.value.push({ name: '', description: '', type: '' });
};

const removeFunctionRow = (index) => {
    newFunctions.value.splice(index, 1);
};

const resetFunctions = () => {
    newFunctions.value = [{ name: '', description: '', type: '' }];
};

const closeFunctionModal = () => {
    openFunctionModal.value = false;
    resetFunctions();
};

const saveFunctions = async () => {
    try {
        for (const func of newFunctions.value) {
            await db.collection('agent-functions').add({
                name: func.name.trim(),
                description: func.description.trim(),
                type: func.type,
            });
        }
        await fetchAgentFunctions();
        closeFunctionModal();
    } catch (error) {
        console.error('Error saving functions:', error);
    }
};
</script>
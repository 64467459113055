<template>
    <v-container>
        <user-search />
        <v-divider class="my-6"></v-divider>
        <session-search />
    </v-container>
</template>

<script>
import UserSearch from '@/components/UserSearch.vue'
import SessionSearch from '@/components/SessionSearch.vue'

export default {
    components: {
        UserSearch,
        SessionSearch
    }
}
</script>
<template>
    <v-card v-if="selectedClinic" class="mt-4">
        <v-card-title class="d-flex align-center">
            Clinic Agents
            <v-spacer></v-spacer>
            <v-text-field v-model="searchQuery" label="Search agents" prepend-inner-icon="mdi-magnify" clearable
                density="compact" hide-details class="mr-4" style="max-width: 300px"
                @input="handleSearch"></v-text-field>
        </v-card-title>

        <!-- Error Alert -->
        <v-alert v-if="error" type="error" dismissible @click:close="error = null" class="ma-4">
            {{ error }}
        </v-alert>

        <!-- Data Table -->
        <v-data-table :headers="headers" :items="filteredAgents" :loading="loading" :items-per-page="pageSize"
            class="elevation-0">
            <template #[`item.access_level`]="{ item }">
                <v-chip :color="getAccessLevelColor(item.access_level)" size="small">
                    {{ item.access_level }}
                </v-chip>
            </template>

            <template #[`item.created_at_unix_secs`]="{ item }">
                {{ formatDate(item.created_at_unix_secs) }}
            </template>

            <template #[`item.actions`]="{ item }">
                <v-btn icon="mdi-information" variant="text" size="small" :loading="item.agent_id === loadingAgentId"
                    @click="showAgentDetails(item.agent_id)">
                </v-btn>
            </template>

            <template #no-data>
                <div class="pa-4 text-center">
                    <v-icon icon="mdi-android" size="48" color="grey-lighten-1" />
                    <div class="text-subtitle-1 mt-2">No agents found for this clinic</div>
                </div>
            </template>
        </v-data-table>

        <!-- Pagination -->
        <v-card-actions v-if="hasMore">
            <v-spacer></v-spacer>
            <v-btn :disabled="!hasMore" @click="loadMore" color="primary" variant="text">
                Load More
            </v-btn>
        </v-card-actions>

        <!-- Agent Details Dialog -->
        <v-dialog v-model="showDialog" fullscreen :scrim="false" transition="dialog-bottom-transition">
            <v-card>
                <v-toolbar color="primary">
                    <v-btn icon="mdi-close" @click="showDialog = false"></v-btn>
                    <v-toolbar-title>Agent Details</v-toolbar-title>
                    <v-spacer></v-spacer>
                </v-toolbar>

                <v-card-text>
                    <v-container v-if="selectedAgentDetails">
                        <!-- Basic Info -->
                        <v-row>
                            <v-col cols="12">
                                <h2 class="text-h5 mb-4">Basic Information</h2>
                                <v-list>
                                    <v-list-item>
                                        <template v-slot:prepend>
                                            <v-icon color="primary">mdi-identifier</v-icon>
                                        </template>
                                        <v-list-item-title>Agent ID</v-list-item-title>
                                        <v-list-item-subtitle>{{ selectedAgentDetails.agent_id }}</v-list-item-subtitle>
                                    </v-list-item>
                                    <v-list-item>
                                        <template v-slot:prepend>
                                            <v-icon color="primary">mdi-account</v-icon>
                                        </template>
                                        <v-list-item-title>Name</v-list-item-title>
                                        <v-list-item-subtitle>{{ selectedAgentDetails.name }}</v-list-item-subtitle>
                                    </v-list-item>
                                </v-list>
                            </v-col>
                        </v-row>

                        <!-- Conversation Config -->
                        <v-row>
                            <v-col cols="12">
                                <h2 class="text-h5 mb-4">Conversation Configuration</h2>

                                <!-- Agent Config -->
                                <v-expansion-panels>
                                    <v-expansion-panel>
                                        <v-expansion-panel-title>
                                            <v-icon start color="primary">mdi-android</v-icon>
                                            Agent Configuration
                                        </v-expansion-panel-title>
                                        <v-expansion-panel-text>
                                            <v-list-item>
                                                <v-list-item-title>First Message</v-list-item-title>
                                                <v-list-item-subtitle>{{
                                                    selectedAgentDetails.conversation_config.agent?.first_message
                                                    }}</v-list-item-subtitle>
                                            </v-list-item>
                                            <v-list-item>
                                                <v-list-item-title>Language</v-list-item-title>
                                                <v-list-item-subtitle>{{
                                                    selectedAgentDetails.conversation_config.agent?.language
                                                    }}</v-list-item-subtitle>
                                            </v-list-item>
                                            <v-list-item>
                                                <div class="mb-4">
                                                    <div class="d-flex align-center mb-2">

                                                        <v-list-item-title>Prompt</v-list-item-title>
                                                        <v-spacer></v-spacer>
                                                        <v-btn density="comfortable" variant="text"
                                                            prepend-icon="mdi-text-box-search"
                                                            @click="showFullPrompt = true">
                                                            View Full Prompt
                                                        </v-btn>
                                                    </div>
                                                    <v-card variant="outlined" class="pa-3">
                                                        {{
                                                            truncateText(selectedAgentDetails.conversation_config?.agent?.prompt.prompt,
                                                                200) }}
                                                    </v-card>
                                                </div>
                                            </v-list-item>
                                            <v-list-item>
                                                <div class="mb-4">
                                                    <div class="text-subtitle-1 font-weight-bold mb-2">Available Tools
                                                    </div>
                                                    <v-chip-group>
                                                        <v-chip
                                                            v-for="tool in selectedAgentDetails.conversation_config?.agent?.prompt.tools"
                                                            :key="tool.name" color="primary" variant="outlined"
                                                            class="ma-1">
                                                            {{ tool.name }}
                                                        </v-chip>
                                                    </v-chip-group>
                                                </div>
                                            </v-list-item>
                                        </v-expansion-panel-text>
                                    </v-expansion-panel>

                                    <!-- ASR Config -->
                                    <v-expansion-panel>
                                        <v-expansion-panel-title>
                                            <v-icon start color="primary">mdi-microphone</v-icon>
                                            ASR Configuration
                                        </v-expansion-panel-title>
                                        <v-expansion-panel-text>
                                            <v-list-item
                                                v-for="(value, key) in selectedAgentDetails.conversation_config.asr"
                                                :key="key">
                                                <v-list-item-title>{{ formatLabel(key) }}</v-list-item-title>
                                                <v-list-item-subtitle>{{ formatValue(value) }}</v-list-item-subtitle>
                                            </v-list-item>
                                        </v-expansion-panel-text>
                                    </v-expansion-panel>

                                    <!-- TTS Config -->
                                    <v-expansion-panel>
                                        <v-expansion-panel-title>
                                            <v-icon start color="primary">mdi-speaker</v-icon>
                                            TTS Configuration
                                        </v-expansion-panel-title>
                                        <v-expansion-panel-text>
                                            <v-list-item
                                                v-for="(value, key) in selectedAgentDetails.conversation_config.tts"
                                                :key="key">
                                                <v-list-item-title>{{ formatLabel(key) }}</v-list-item-title>
                                                <v-list-item-subtitle>{{ formatValue(value) }}</v-list-item-subtitle>
                                            </v-list-item>
                                        </v-expansion-panel-text>
                                    </v-expansion-panel>

                                    <!-- Platform Settings -->
                                    <v-expansion-panel>
                                        <v-expansion-panel-title>
                                            <v-icon start color="primary">mdi-cog</v-icon>
                                            Platform Settings
                                        </v-expansion-panel-title>
                                        <v-expansion-panel-text>
                                            <v-list-item v-for="(value, key) in selectedAgentDetails.platform_settings"
                                                :key="key">
                                                <v-list-item-title>{{ formatLabel(key) }}</v-list-item-title>
                                                <v-list-item-subtitle>{{ formatValue(value) }}</v-list-item-subtitle>
                                            </v-list-item>
                                        </v-expansion-panel-text>
                                    </v-expansion-panel>
                                </v-expansion-panels>
                            </v-col>
                        </v-row>
                    </v-container>
                    <div v-else-if="loadingAgentDetails" class="d-flex justify-center align-center"
                        style="height: 400px">
                        <v-progress-circular indeterminate color="primary"></v-progress-circular>
                    </div>
                </v-card-text>
                <!-- Full Prompt Dialog -->
                <v-dialog v-model="showFullPrompt" max-width="800">
                    <v-card>
                        <v-toolbar color="primary">
                            <v-toolbar-title>Agent Prompt</v-toolbar-title>
                            <v-spacer></v-spacer>
                            <v-btn v-if="!isEditingPrompt" icon="mdi-pencil" variant="text"
                                @click="startEditingPrompt"></v-btn>
                            <v-btn icon="mdi-close" @click="closePromptDialog"></v-btn>
                        </v-toolbar>
                        <v-card-text class="pa-4">
                            <v-card variant="outlined" class="pa-4">
                                <v-textarea v-if="isEditingPrompt" v-model="editedPrompt" auto-grow variant="outlined"
                                    :loading="updatingPrompt" hide-details class="prompt-textarea"></v-textarea>
                                <pre v-else class="prompt-text">{{
                                    selectedAgentDetails.conversation_config?.agent?.prompt.prompt }}</pre>
                            </v-card>
                        </v-card-text>
                        <v-card-actions v-if="isEditingPrompt">
                            <v-spacer></v-spacer>
                            <v-btn color="error" variant="text" :disabled="updatingPrompt" @click="cancelEdit">
                                Cancel
                            </v-btn>
                            <v-btn color="primary" :loading="updatingPrompt" @click="savePrompt">
                                Save Changes
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>


            </v-card>
        </v-dialog>
    </v-card>
</template>


<script>
import { ref, watch } from 'vue';
import { getFunctions, httpsCallable } from 'firebase/functions';

export default {
    name: 'ClinicAgents',

    props: {
        selectedClinic: {
            type: Object,
            required: true
        }
    },

    setup(props) {
        //const agents = ref([]);
        const filteredAgents = ref([]);
        const loading = ref(false);
        const searchQuery = ref('');
        const pageSize = ref(30);
        const hasMore = ref(false);
        const nextCursor = ref(null);
        const error = ref(null);
        const showDialog = ref(false);
        const selectedAgentDetails = ref(null);
        const loadingAgentDetails = ref(false);
        const loadingAgentId = ref(null);
        const showFullPrompt = ref(false);

        const isEditingPrompt = ref(false);
        const editedPrompt = ref('');
        const updatingPrompt = ref(false);




        const headers = ref([
            { title: 'Phone Number', key: 'phoneNumber', width: '150px' },
            { title: 'Name', key: 'name' },
            { title: 'Agent ID', key: 'agent_id' },
            { title: 'Created At', key: 'created_at_unix_secs' },
            { title: 'Access Level', key: 'access_level' },
            { title: 'Actions', key: 'actions', sortable: false, width: '100px' },
        ]);

        const fetchAgents = async (cursor = null) => {
            if (!props.selectedClinic?.agentIds) {
                filteredAgents.value = [];
                return;
            }

            try {
                loading.value = true;
                error.value = null;

                const functions = getFunctions();
                const getAgents = httpsCallable(functions, 'getAgents');

                const params = {
                    page_size: pageSize.value,
                    ...(cursor && { cursor }),
                    ...(searchQuery.value && { search: searchQuery.value }),
                };

                const result = await getAgents({ params });
                const response = result.data;

                // Filter agents based on clinic's agentIds
                const clinicAgentIds = Object.values(props.selectedClinic.agentIds);
                const phoneNumbers = Object.keys(props.selectedClinic.agentIds);

                const matchedAgents = response.agents
                    .filter(agent => clinicAgentIds.includes(agent.agent_id))
                    .map(agent => ({
                        ...agent,
                        phoneNumber: phoneNumbers[clinicAgentIds.indexOf(agent.agent_id)] || 'N/A'
                    }));

                if (cursor) {
                    // Append new agents to existing list
                    filteredAgents.value = [...filteredAgents.value, ...matchedAgents];
                } else {
                    // Replace existing agents
                    filteredAgents.value = matchedAgents;
                }

                hasMore.value = response.has_more;
                nextCursor.value = response.next_cursor;
            } catch (error) {
                console.error('Error fetching agents:', error);
                error.value = error.message || 'Error fetching agents';
            } finally {
                loading.value = false;
            }
        };

        const fetchAgentDetails = async (agentId) => {
            loadingAgentId.value = agentId;
            loadingAgentDetails.value = true;
            try {
                const functions = getFunctions();
                const getAgentDetails = httpsCallable(functions, 'getAgentDetails');
                const result = await getAgentDetails({ agentId });
                selectedAgentDetails.value = result.data;
                console.log('Agent details:', selectedAgentDetails.value);
                showDialog.value = true;
            } catch (err) {
                console.error('Error fetching agent details:', err);
                error.value = 'Error loading agent details';
            } finally {
                loadingAgentDetails.value = false;
                loadingAgentId.value = null;
            }
        };

        const showAgentDetails = async (agentId) => {
            selectedAgentDetails.value = null;
            await fetchAgentDetails(agentId);
        };

        const formatLabel = (key) => {
            return key
                .split('_')
                .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                .join(' ');
        };

        const formatValue = (value) => {
            if (typeof value === 'boolean') {
                return value ? 'Yes' : 'No';
            }
            if (Array.isArray(value)) {
                return value.join(', ');
            }
            if (typeof value === 'object' && value !== null) {
                return JSON.stringify(value, null, 2);
            }
            return value;
        };


        const truncateText = (text, length) => {
            console.log('text:', text);
            if (!text) return '';
            return text.length > length ? text.substring(0, length) + '...' : text;
        };


        const handleSearch = debounce(() => {
            fetchAgents();
        }, 500);

        const loadMore = async () => {
            if (hasMore.value && nextCursor.value) {
                await fetchAgents(nextCursor.value);
            }
        };

        const getAccessLevelColor = (level) => {
            const colors = {
                admin: 'red',
                editor: 'orange',
                viewer: 'green',
            };
            return colors[level] || 'grey';
        };

        const startEditingPrompt = () => {
            editedPrompt.value = selectedAgentDetails.value.conversation_config?.agent?.prompt.prompt || '';
            isEditingPrompt.value = true;
        };

        const cancelEdit = () => {
            isEditingPrompt.value = false;
            editedPrompt.value = '';
        };

        const closePromptDialog = () => {
            if (isEditingPrompt.value) {
                cancelEdit();
            }
            showFullPrompt.value = false;
        };
        const savePrompt = async () => {
            if (!selectedAgentDetails.value?.agent_id) return;

            updatingPrompt.value = true;
            try {
                const functions = getFunctions();
                const updateAgent = httpsCallable(functions, 'updateAgent');

                await updateAgent({
                    agentId: selectedAgentDetails.value.agent_id,
                    updateData: {
                        conversation_config: {
                            agent: {
                                prompt: {
                                    prompt: editedPrompt.value
                                }
                            }
                        }
                    }
                });

                // Update the local state
                selectedAgentDetails.value.conversation_config.agent.prompt.prompt = editedPrompt.value;
                isEditingPrompt.value = false;

                // Show success message
                // You might want to add a success alert here
            } catch (err) {
                console.error('Error updating agent prompt:', err);
                error.value = 'Error updating agent prompt';
            } finally {
                updatingPrompt.value = false;
            }
        };


        const formatDate = (unixTimestamp) => {
            return new Date(unixTimestamp * 1000).toLocaleString();
        };

        // Watch for changes in selectedClinic
        watch(() => props.selectedClinic, (newClinic) => {
            if (newClinic) {
                fetchAgents();
            } else {
                filteredAgents.value = [];
            }
        }, { immediate: true });

        return {
            filteredAgents,
            loading,
            searchQuery,
            pageSize,
            hasMore,
            error,
            headers,
            showDialog,
            selectedAgentDetails,
            loadingAgentDetails,
            loadingAgentId,
            showAgentDetails,
            formatLabel,
            formatValue,
            formatDate,
            handleSearch,
            loadMore,
            showFullPrompt,
            truncateText,
            getAccessLevelColor,
            isEditingPrompt,
            editedPrompt,
            updatingPrompt,
            startEditingPrompt,
            cancelEdit,
            closePromptDialog,
            savePrompt,
        };
    }
};

// Debounce utility function
function debounce(func, wait) {
    let timeout;
    return function executedFunction(...args) {
        const later = () => {
            clearTimeout(timeout);
            func(...args);
        };
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
    };
}

</script>

<style scoped>
.v-expansion-panels {
    margin-bottom: 16px;
}

.prompt-text {
    white-space: pre-wrap;
    word-wrap: break-word;
    font-family: monospace;
    font-size: 14px;
    line-height: 1.5;
    max-height: 70vh;
    overflow-y: auto;
}
</style>
